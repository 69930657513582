import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AFKGuidesTeamsPVP: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Supreme Arena Teams</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_sarena.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Supreme Arena Teams [Week 4]</h1>
          <h2>
            Best teams to use in AFK Journey Supreme Arena/PVP mode - both on
            defense and offense.
          </h2>
          <p>
            Last updated: <strong>12/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          In the AFK Journey Supreme Arena, many of the archetypes used in the
          non-season arena can be used here as well, but many opportunities are
          made available by season talents and artifacts. For attack teams, it
          is often necessary to tailor your teams based on what the other player
          is running, so below we will list some solid defense teams.
        </p>
        <p>Check out our Arena Guide here!</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Best Arena Teams"
            link="/afk-journey/guides/arena-teams"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_meta.jpg"
                alt="Best Arena Teams"
              />
            }
          />
        </Row>
        <SectionHeader title="Map 1" />
        <h5>Wilder Burst Team</h5>
        <StaticImage
          src="../../../images/afk/dream_s/sarena_1.webp"
          alt="Guides"
        />
        <div className="heroes with-margin">
          <div className="employees-container afk-cards">
            <AFKCharacter
              mode="card"
              slug="eironn"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="ulmus"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="arden"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="scarlita"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="lorsan"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </div>
          <div className="info">
            <p>
              This team is relatively accessible thanks to not necessarily
              requiring Celestial/Hypogean heroes, while also being very
              powerful against many matchups. We can make use of the hidden
              tiles to make it so that the enemy does not bring Lily May at
              first glance.
            </p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="eironn" enablePopover /> is
                used to group up and debuff enemies and is the core of this
                archetype.
              </li>
              <ul className="bigger-margin">
                <li>
                  Of course, we can always run other sources of CC for our
                  frontline, such as{' '}
                  <AFKCharacter
                    mode="inline"
                    slug="granny-dahnie"
                    enablePopover
                  />
                  , <AFKCharacter mode="inline" slug="lumont" enablePopover />,
                  or <AFKCharacter mode="inline" slug="lucca" enablePopover />,
                  but they will lack the explosive potential of the main{' '}
                  <AFKCharacter mode="inline" slug="eironn" enablePopover />{' '}
                  variant.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="ulmus" enablePopover /> is
                used to root enemies after{' '}
                <AFKCharacter mode="inline" slug="eironn" enablePopover />{' '}
                groups them up, and act as a frontline along with Eironn
              </li>
              <ul className="bigger-margin">
                <li>
                  Ulmus is not strictly necessary but he does make the team a
                  lot more consistent. Another option is running more damage
                  carries such as the ones listed below, or even{' '}
                  <AFKCharacter mode="inline" slug="bryon" enablePopover /> in
                  order to counter an enemy{' '}
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  who may interfere with Eironn’s ultimate.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="arden" enablePopover /> is the
                preferred damage carry for this team, and his dupes are readily
                available
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="carolina" enablePopover />{' '}
                  can be used instead of or along with{' '}
                  <AFKCharacter mode="inline" slug="arden" enablePopover /> as
                  long as we drop one of our supports for more damage.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="parisa" enablePopover /> is
                  just as powerful as a burst damage damage carry, for those
                  lacking either of the others.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="scarlita" enablePopover />{' '}
                provides some shields allowing the team to survive the initial
                few seconds, while also providing some explosive potential to
                close out games
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="damian" enablePopover /> can
                  be used for more sustain, as he provides some initial healing
                  that helps keep the backline alive at the start of battle,
                  while also providing Haste and blinding enemies.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="damian" enablePopover />{' '}
                  complements{' '}
                  <AFKCharacter mode="inline" slug="arden" enablePopover />,
                  adding more burst damage to the team.
                </li>
                <li>
                  <AFKCharacter
                    mode="inline"
                    slug="elijah-and-lailah"
                    enablePopover
                  />{' '}
                  can be used in place of{' '}
                  <AFKCharacter mode="inline" slug="damian" enablePopover /> as
                  a higher investment alternative, by making use of the
                  connected tiles at the back to greatly speed up our Ultimates
                  and provide some healing and stat stacking, and work perfectly
                  in this mono faction team.
                </li>
                <li>
                  Alternatively, we can opt for an alternate damage carry, such
                  as <AFKCharacter mode="inline" slug="bonnie" enablePopover />,
                  providing long lasting AOE damage, or{' '}
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  to counter enemy characters with dangerous ultimates.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="lorsan" enablePopover /> takes
                advantage of the burst damage archetype to double up on{' '}
                <AFKCharacter mode="inline" slug="arden" enablePopover /> damage
                while also providing a powerful burst of healing by the time{' '}
                <AFKCharacter mode="inline" slug="damian" enablePopover /> cart
                is destroyed. The Wilder talent tile makes it so that Lorsan can
                usually survive long enough to trigger his healing burst, though
                depending on the matchup he can still die as he is quite
                squishy.
              </li>
              <ul className="bigger-margin">
                <li>
                  As this is more of a flex slot, If we don’t have{' '}
                  <AFKCharacter mode="inline" slug="lorsan" enablePopover />
                  another option is running{' '}
                  <AFKCharacter mode="inline" slug="hewynn" enablePopover />, as
                  she charges up her ultimate by the time her invulnerability
                  runs out.
                </li>
                <li>
                  We can also run a more offensive alternative relying on more
                  damage carries.
                </li>
                <li>
                  Celestials/Hypogeans such as{' '}
                  <AFKCharacter mode="inline" slug="berial" enablePopover /> or{' '}
                  <AFKCharacter mode="inline" slug="harak" enablePopover /> can
                  be useful as an additional threat with more staying power.
                  <AFKCharacter
                    mode="inline"
                    slug="reinier"
                    enablePopover
                  />{' '}
                  can be used to disrupt the enemy formation.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <h5>Wilder Burst Team (No Celehypo variant) </h5>
        <StaticImage
          src="../../../images/afk/dream_s/sarena_2.webp"
          alt="Guides"
        />
        <p>
          All the previous tips apply here,{' '}
          <AFKCharacter mode="inline" slug="ludovic" enablePopover /> is there
          to provide that same initial sustain that{' '}
          <AFKCharacter mode="inline" slug="scarlita" enablePopover /> would
          otherwise provide
        </p>
        <SectionHeader title="Map 2" />
        <h5>Twins Summoner Team</h5>
        <StaticImage
          src="../../../images/afk/dream_s/sarena_3.webp"
          alt="Guides"
        />
        <div className="heroes with-margin">
          <div className="employees-container afk-cards">
            <AFKCharacter
              mode="card"
              slug="elijah-and-lailah"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="harak"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="florabelle"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="hewynn"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="berial"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </div>
          <div className="info">
            <p>
              This is a high investment team at its core, thanks to the map
              greatly favouring teams with The Twins, but it can easily be
              adapted to a F2P variant as well. The intent is to make use of the
              map bonus which debuffs enemies whenever any unit dies.
            </p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="elijah-and-lailah"
                  enablePopover
                />{' '}
                are used for some healing as well as providing massive amounts
                of Haste and speeding up out ultimates considerably.
              </li>
              <ul className="bigger-margin">
                <li>
                  Lacking{' '}
                  <AFKCharacter
                    mode="inline"
                    slug="elijah-and-lailah"
                    enablePopover
                  />
                  , another good C/H option to bring here is{' '}
                  <AFKCharacter mode="inline" slug="phraesto" enablePopover />,
                  for the extra body.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="harak" enablePopover /> is
                used as a big threat for the enemy to deal with, with potential
                to snowball if the enemy is using a minion-centric build.
              </li>
              <ul className="bigger-margin">
                <li>
                  Another option that performs the same role is{' '}
                  <AFKCharacter mode="inline" slug="seth" enablePopover />,
                  otherwise, just bring more healing.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="cecia" enablePopover /> is
                  also a viable option, but she can be a tad slow for the
                  current pvp meta.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="florabelle" enablePopover />{' '}
                is one of the core units for the archetype, thanks to her
                summons synergizing greatly with{' '}
                <AFKCharacter mode="inline" slug="berial" enablePopover />.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="cecia" enablePopover />{' '}
                  works as a slower, budget option.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  can help The Twins avoid burst damage.
                </li>
                <li>Otherwise, bring any other DPS carry.</li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="hewynn" enablePopover />
                provides a big burst of healing, along with some damage
                resistance, to complement The Twins.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover /> is
                  another good option for making our team tankier
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="hugin" enablePopover /> can
                  also work, with good positioning
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="berial" enablePopover />s
                another core unit for the summoner archetype, taking great
                advantage of the map mechanic while being hard to kill.
              </li>
              <ul className="bigger-margin">
                <li>
                  Lacking{' '}
                  <AFKCharacter mode="inline" slug="berial" enablePopover />
                  ,any other dps carry will do.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <h5>Budget Summoner Team</h5>
        <StaticImage
          src="../../../images/afk/dream_s/sarena_4.webp"
          alt="Guides"
        />
        <p>
          This version is built around{' '}
          <AFKCharacter mode="inline" slug="florabelle" enablePopover /> and{' '}
          <AFKCharacter mode="inline" slug="cecia" enablePopover />, with{' '}
          <AFKCharacter mode="inline" slug="phraesto" enablePopover /> giving an
          extra body. <AFKCharacter mode="inline" slug="lenya" enablePopover />{' '}
          helps buy time for the backline to use their ultimate, while being a
          powerful threat herself, taking advantage of the map debuff.
        </p>
        <SectionHeader title="Map 3" />
        <h5>Tasi Talene Team</h5>

        <StaticImage
          src="../../../images/afk/dream_s/sarena_5.webp"
          alt="Guides"
        />
        <div className="heroes with-margin">
          <div className="employees-container afk-cards">
            <AFKCharacter
              mode="card"
              slug="tasi"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="talene"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="phraesto"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="lily-may"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="damian"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </div>
          <div className="info">
            <p>
              This team is one of the higher end options for players who
              collected some of the best C/H characters over the seasons, with
              strong staying power, capable of surviving against high damage
              teams and coming back for more. The hidden tiles help hide the
              core units of our team and make it more unpredictable.
            </p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="tasi" enablePopover /> is one
                of the main victory conditions for this team, often capable of
                coming back after death to slay the enemy team all by herself.
              </li>
              <ul className="bigger-margin">
                <li>
                  While <AFKCharacter mode="inline" slug="tasi" enablePopover />{' '}
                  is a key component of this team, lacking Tasi, it is possible
                  to run a team tailored around{' '}
                  <AFKCharacter mode="inline" slug="talene" enablePopover />{' '}
                  instead, in which case{' '}
                  <AFKCharacter mode="inline" slug="ludovic" enablePopover /> or{' '}
                  <AFKCharacter mode="inline" slug="hewynn" enablePopover /> can
                  be a great addition here, though the team will be lacking in
                  offensive potential without Tasi.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="talene" enablePopover /> is
                our second main victory condition, capable of regenerating after
                being defeated as long as another ally is still alive on the
                field, providing incredible synergy with{' '}
                <AFKCharacter mode="inline" slug="tasi" enablePopover />
                and <AFKCharacter mode="inline" slug="damian" enablePopover />.
                The talent tile also greatly enhances Talene’s ultimate and
                grants her extra stats.
              </li>
              <ul className="bigger-margin">
                <li>
                  It is possible to run a version of this team without{' '}
                  <AFKCharacter mode="inline" slug="talene" enablePopover />,
                  focusing instead on disruption.{' '}
                  <AFKCharacter mode="inline" slug="temesia" enablePopover />{' '}
                  can be a great choice for this variant.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="igor" enablePopover /> can
                  be used as an alternative distraction to keep{' '}
                  <AFKCharacter mode="inline" slug="tasi" enablePopover />
                  and <AFKCharacter
                    mode="inline"
                    slug="damian"
                    enablePopover
                  />{' '}
                  in the game.
                </li>
                <li>
                  We can also just run extra healing, or another damage carry,
                  though the team loses a lot of what makes it special without
                  <AFKCharacter mode="inline" slug="talene" enablePopover />.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover /> is
                currently the best tank in the game, dividing damage between two
                meat shields, while also providing valuable Energy Regen to
                allies.
              </li>
              <ul className="bigger-margin">
                <li>
                  Lacking{' '}
                  <AFKCharacter mode="inline" slug="phraesto" enablePopover />,{' '}
                  <AFKCharacter mode="inline" slug="thoran" enablePopover /> is
                  another great option with some offensive potential with his
                  counter.
                </li>
                <li>
                  Any other tank can be used in this slot, just make sure they
                  can hold out long enough for{' '}
                  <AFKCharacter mode="inline" slug="talene" enablePopover /> and{' '}
                  <AFKCharacter mode="inline" slug="tasi" enablePopover /> to do
                  their thing.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                provides some extra immediate damage and also counters{' '}
                <AFKCharacter mode="inline" slug="eironn" enablePopover /> teams
                to a certain extent
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="scarlita" enablePopover />
                  can also be used to complete our requirement for the talent
                  tile, and also helps keep
                  <AFKCharacter mode="inline" slug="tasi" enablePopover /> alive
                  a little longer, while also providing an extra victory
                  condition later in the fight as she crashes down.
                </li>
                <li>
                  Likewise,{' '}
                  <AFKCharacter mode="inline" slug="bonnie" enablePopover />{' '}
                  also works, especially if running a Wilder talent tile
                  variant.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="damian" enablePopover /> is
                the only healer that can keep up with{' '}
                <AFKCharacter mode="inline" slug="talene" enablePopover /> and{' '}
                <AFKCharacter mode="inline" slug="tasi" enablePopover />
                against burst teams, as he can just summon a new cart to quickly
                heal Talene and Tasi back up after the rest of the team is wiped
                by a powerful attack. Works at Mythic+.
              </li>
              <ul className="bigger-margin">
                <li>
                  Lacking{' '}
                  <AFKCharacter mode="inline" slug="damian" enablePopover />, we
                  can run any other healer here, though the team will be a lot
                  weaker against burst damage, as Damian is the only one who can
                  survive to provide backup healing for Talene and Tasi.
                </li>
              </ul>
            </ul>
            <p>
              Overall, <AFKCharacter mode="inline" slug="tasi" enablePopover />,{' '}
              <AFKCharacter mode="inline" slug="talene" enablePopover /> and{' '}
              <AFKCharacter mode="inline" slug="damian" enablePopover /> are the
              most important pieces, so when looking for substitutions,
              prioritize replacing{' '}
              <AFKCharacter mode="inline" slug="lily-may" enablePopover />
              and/or{' '}
              <AFKCharacter mode="inline" slug="phraesto" enablePopover />.
            </p>
          </div>
        </div>
        <h5>Mauler Odie Hypercarry</h5>
        <StaticImage
          src="../../../images/afk/dream_s/sarena_6.webp"
          alt="Guides"
        />
        <div className="heroes with-margin">
          <div className="employees-container afk-cards">
            <AFKCharacter
              mode="card"
              slug="odie"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="smokey-and-meerky"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="lumont"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="koko"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="shakir"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </div>
          <div className="info">
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> is the
                main damage carry, and one of the few characters who function
                well in a hypercarry scenario with multiple bufss.
              </li>
              <ul className="bigger-margin">
                <li>
                  While{' '}
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  is a higher burst damage option, though with less sustained
                  DPS.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="soren" enablePopover />
                  can also be used if we pivot to a CC-centric team
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                provides some healing and buffs right away, buying time for{' '}
                <AFKCharacter mode="inline" slug="odie" enablePopover /> to do
                their thing.
              </li>
              <ul className="bigger-margin">
                <li>
                  If you swap{' '}
                  <AFKCharacter mode="inline" slug="odie" enablePopover /> for a
                  unit that dives into the enemy formation often,{' '}
                  <AFKCharacter mode="inline" slug="mikola" enablePopover /> can
                  replace{' '}
                  <AFKCharacter
                    mode="inline"
                    slug="smokey-and-meerky"
                    enablePopover
                  />
                  .
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="lumont" enablePopover /> is a
                great mauler tank for distracting the enemy, which helps with
                out game plan as{' '}
                <AFKCharacter mode="inline" slug="odie" enablePopover /> quickly
                chews through the enemy.
              </li>
              <ul className="bigger-margin">
                <li>
                  Any other tank can be used in this slot, just make sure they
                  can hold out long enough for{' '}
                  <AFKCharacter mode="inline" slug="odie" enablePopover /> to do
                  his thing.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover />{' '}
                provides a slow, but powerful ultimate that helps protect the
                team with shields, healing and buffs.
              </li>
              <ul className="bigger-margin">
                <li>
                  Another option is to run
                  <AFKCharacter mode="inline" slug="hewynn" enablePopover />,
                  who also has a slow but powerful ultimate.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover />
                ’s purpose here is to hold the enemy team down while also being
                very hard to kill once he casts his ultimate.
              </li>
              <ul className="bigger-margin">
                <li>
                  Lacking{' '}
                  <AFKCharacter mode="inline" slug="shakir" enablePopover />, we
                  can run another tank to keep the enemy busy.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <SectionHeader title="Video" />
        <p>Here are some additional ideas for teams to use:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="qlN9VxXBx7o" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesTeamsPVP;

export const Head: React.FC = () => (
  <Seo
    title="Supreme Arena Teams | AFK Journey | Prydwen Institute"
    description="Best teams to use in AFK Journey Supreme Arena/PVP mode - both on defense and offense."
    game="afk"
  />
);
